import { Dispatch } from "../Dispatch";
import { DialogKind } from "./DialogEntities";
import { DeclineTncConsent } from '../TncPrivacy/TncConsentPopup';
import { FeatureFlags } from "../../Config/FeatureFlags";
import appstore from "../../appStore";
import { CredentialPopupTrigger, UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { DescriptiveDialogConfig, DesktopDialogConfigStore, MobileDialogConfigStore } from "./DialogConfigDevice";
import { DeclineProfileValidationProcess, IsUnderProfileValidationMode } from "../LoginValidation/LoginValidationHelper";
import { VerificationTrigger } from "../Verification/VerificationEntities";
import { History } from 'history';
import { DeclineLegalConsent } from "../LegalDocuments/UpdatedLegalDocumentsConsent";
import { GetValues } from "../../Config/MyAppConfig";
import { ClearAddressPointDataAndCloseDialog } from "../AddressPoints/UI/AddressPointActions";
import { ResetBookingForm } from "../BookingTemplate/BookingTemplateHelper";
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";

/**
 * Common configuration per dialog (subset of all dialogs).
 * Two factors decide whether <CommonDialogHeader/> display or not:
 *   1) DialogConfig object is not null;
 *   2) HideCommonDialogHeader.
 */
export interface DialogConfig {

    /** Defines title which is displayed in <CommonDialogHeader/> */
    Title?: string;

    /** Defines description which is displayed in <CommonDialogDescription/> */
    Description?: string;

    /** Show logo rather than title if value is true */
    ShowLogo?: boolean;

    /** CommonDialogHeader will show by default, unless this value is true */
    HideCommonDialogHeader?: boolean;

    /** Defines whether to require a slide animation when pops up, only used for mobile for now. */
    RequireAnimation: boolean;
    
    /** Defines whether to show <back button in <CommonDialogHeader/> */
    RequireBackButton: boolean;

    /** Defines custom behaviour of <back button in <CommonDialogHeader/>, if there is any. */
    CustomBackButtonBehaviour?: (history: History) => void;

    /** Defines whether to show X button in <CommonDialogHeader/>, but some dialog does not need it, such as Cancel booking */
    RequireCloseButton: boolean;

    /** Defines custom behaviour of X button, if there is any, which is only used for desktop version */
    CustomCloseBehaviour?: () => void;

    /** When this is populated, add a Help button in the header linked to the provided URL. */
    HelpButtonLink: string | null;
}

/** 
 * Acts like a lookup function, and defines all custom configuration per DialogKind enum value with type DialogConfigStoreKind.
 * The value is optional.
 */
const DialogConfigStore: Partial<Record<DialogKind, DialogConfig | undefined>> = {

    /** Log in */
    [DialogKind.LogIn]: {
        Title: "Log in",
        Description: "Please enter your email and password below",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {

            // some brands like Cabcharge can't be used as guest. Therefore can't close the login dialog unless logged in successfully.
            if (FeatureFlags.ForceLogin) {
                return;
            }

            const { Credentials } = appstore.getState().uiLogicControl;

            if (Credentials.ApiRunningStatus !== DataLoadingStatus.Idle) {
                return;
            }

            Dispatch.Dialog.CloseTopDialog();
        },
        HelpButtonLink: null
    },

    /** Sign up */
    [DialogKind.SignUp]: {
        Title: "Sign up",
        Description: "It's a free, faster and smarter way to book",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {

            const { Credentials } = appstore.getState().uiLogicControl;

            if (Credentials.ApiRunningStatus !== DataLoadingStatus.Idle) {
                return;
            }

            new CredentialsController().ClosePopup();
        },
        HelpButtonLink: null
    },

    /** Sign up dialog opened via add card flow */
    [DialogKind.SignUpToAddCard]: {
        Title: "Add card",
        Description: "A quick profile setup",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {

            const { Credentials } = appstore.getState().uiLogicControl;

            if (Credentials.ApiRunningStatus !== DataLoadingStatus.Idle) {
                return;
            }

            new CredentialsController().ClosePopup();
        },
        HelpButtonLink: null
    },

    /** Sign up dialog opened via pay driver direct flow */
    [DialogKind.SignupToPayDriverDirect]: {
        Title: "Sign up",
        Description: "To pay the driver directly, a profile is required",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {

            const { Credentials } = appstore.getState().uiLogicControl;

            if (Credentials.ApiRunningStatus !== DataLoadingStatus.Idle) {
                return;
            }

            new CredentialsController().ClosePopup();
        },
        HelpButtonLink: null
    },

    /** Forgot password */
    [DialogKind.ForgotPassword]: {
        Title: "Forgot password",
        Description: "Enter your email below and we'll send a link to reset your password",
        RequireAnimation: true,
        RequireBackButton: true,
        RequireCloseButton: true,
        CustomBackButtonBehaviour(): void {
            new CredentialsController().ClosePopup();
            Dispatch.Dialog.ShowDialog(DialogKind.LogIn);
        },
        CustomCloseBehaviour(): void {

            if (FeatureFlags.ForceLogin) {
                return;
            }

            new CredentialsController().ClosePopup();
            Dispatch.Dialog.ShowDialog(DialogKind.LogIn);
        },
        HelpButtonLink: null
    },

    /** Tnc consent which is used in logged-in user validation process */
    [DialogKind.TncConsent]: {
        Title: "Terms and conditions",
        ShowLogo: true,
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomBackButtonBehaviour(): void {
            DeclineTncConsent();
        },
        CustomCloseBehaviour(): void {
            DeclineTncConsent();
        },
        HelpButtonLink: null
    },

    /** Verification */
    [DialogKind.Verification]: {
        Title: "Verification",
        Description: "To check we have your details correct, an access code was sent to:",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {
            VerificationClose();
        },
        HelpButtonLink: null
    },

    /** Contact details */
    [DialogKind.ContactDetails]: {
        Title: "Your contact number",
        Description: "Please use a mobile or Australian landline number.",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        CustomCloseBehaviour(): void {
            VerificationClose();
        },
        HelpButtonLink: null
    },

    /** Cancel booking */
    [DialogKind.CancelBooking]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    /** Share booking */
    [DialogKind.ShareBooking]: {
        Title: "Share booking",
        Description: "Share via SMS:",
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },

    /** Payment Wallet */
    [DialogKind.PaymentWallet]: {
        Title: "Payment",
        Description: "Payment by card is automatically processed at the end of the trip",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: GetValues().Payment?.PaymentFaqLink ?? null
    },

    /** User personal details */
    [DialogKind.UserPersonalDetails]: {
        Title: "My Profile",
        Description: "Personal information you've entered to create your profile",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    /** Add payment card */
    [DialogKind.AddPaymentCard]: {
        Title: "Add New Card",
        Description: "Add New Card",
        RequireAnimation: true,
        RequireBackButton: true,
        RequireCloseButton: false,
        CustomBackButtonBehaviour() : void {
            Dispatch.Payment.ToggleCardRegistrationPanel(false);
        },
        HelpButtonLink: null
    },

    /** Contact us */
    [DialogKind.ContactUs]: {
        Title: "Contact us",
        Description: "The more we know, the more we can help",
        RequireAnimation: false,
        RequireBackButton: true,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    /** Descriptive error message */
    [DialogKind.DescriptiveErrorMessage]: {
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HideCommonDialogHeader: true,
        HelpButtonLink: null
    },

    /** Multi-tenant login selector */
    [DialogKind.MultiTenantSelector]: {
        Title: " ",
        Description: "Please select your profile type",
        RequireAnimation: true,
        RequireBackButton: true,
        RequireCloseButton: true,
        HelpButtonLink: null
    },

    /** Legal documents consent V2. */
    [DialogKind.LegalDocumentsConsent]: {
        Title: "",
        ShowLogo: true,
        RequireAnimation: true,
        RequireBackButton: false,
        RequireCloseButton: true,
        HideCommonDialogHeader: true,
        CustomBackButtonBehaviour(): void {
            DeclineLegalConsent();
        },
        CustomCloseBehaviour(): void {
            DeclineLegalConsent();
        },
        HelpButtonLink: null
    },

    /** Add favourite */
    [DialogKind.AddFavourite]: {
        Description: "Create Your Favourite Trips",
        RequireAnimation: true,
        RequireBackButton: true,
        CustomBackButtonBehaviour(): void {
            ResetBookingForm(BookingWidgetModeKind.Booking);
            Dispatch.Dialog.CloseTopDialog();
        },
        RequireCloseButton: false,
        HelpButtonLink: null
    },

     /** Cancel booking */
     [DialogKind.ClickAndCollectInfo]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },

    [DialogKind.IE11DeprecationWarning]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },

    /** Address Points. This is a dialog only in mobile. */
    [DialogKind.AddressPoints]: {
        Title: "Saved Addresses",
        Description: "Addresses that you save once and select to book a trip faster",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },
    [DialogKind.CreateAddressPoint]: {
        Title: "Add a new address",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null,
        CustomCloseBehaviour(): void {
            ClearAddressPointDataAndCloseDialog();
        }
    },
    [DialogKind.EditAddressPoint]: {
        Title: "Edit address",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null,
        CustomCloseBehaviour(): void {
            ClearAddressPointDataAndCloseDialog();
        }
    },

    [DialogKind.PassengerInfo]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },

    [DialogKind.WhatsNew]: {
        Title: "What's New",
        HideCommonDialogHeader: false,
        RequireAnimation: false,
        RequireBackButton: true,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    [DialogKind.NewFeature]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    [DialogKind.AvailableFeatures]: {
        Title: "Available Features",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },

    [DialogKind.PriceGuaranteeFAQ]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },
    [DialogKind.AddPaymentMethod]: {
        Title: "Add Payment Method",
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null,
        CustomCloseBehaviour: () => {
            Dispatch.Payment.HideAddPaymentMethodDialog();
        }
    },
    [DialogKind.UnlinkPayPal]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },
    [DialogKind.TechnicalDifficulties]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: false,
        HelpButtonLink: null
    },
    /** Intermediate dialog opened before sign up (via pay driver direct link) to inform the user about disadvantages of paying the driver directly. */
    [DialogKind.PayDriverDirectInfo]: {
        HideCommonDialogHeader: true,
        RequireAnimation: false,
        RequireBackButton: false,
        RequireCloseButton: true,
        HelpButtonLink: null
    },
}

/**
 * Lookup against DialogConfigStore with per device overwriting.
 * All dialog interfaces/components should NOT use DialogConfigStore directly, and they should lookup dialog config via this function.
 */
export function DialogConfigLookup(dialogKind: DialogKind): DialogConfig | undefined {

    const config = DialogConfigStore[dialogKind];

    if (!config) return config;

    // Check device specific configuration
    const { LayoutMode } = appstore.getState().uiLogicControl;

    let overwrite = LayoutMode === UILayoutMode.Mobile ? MobileDialogConfigStore[dialogKind] : DesktopDialogConfigStore[dialogKind];

    const { detailedErrorMessage } = appstore.getState().dialog;

    if (dialogKind === DialogKind.DescriptiveErrorMessage && detailedErrorMessage.ButtonText === "Try again") {
        overwrite = {...overwrite, ...DescriptiveDialogConfig[dialogKind]};
    }

    if (!overwrite) return config;

    const config2: DialogConfig = {
        ...config,
        ...overwrite
    }

    return config2;
}

/**
 * This function is used for both <Verification/> and <ContactDetails/> when X button clicked.
 */
function VerificationClose() {
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);
    Dispatch.Verification.ClearContactNumber();
            
    if (IsUnderProfileValidationMode()) { 
        DeclineProfileValidationProcess(); 
    }

    if (appstore.getState().verification.Trigger === VerificationTrigger.Signup){
        Dispatch.Dialog.ShowDialog(DialogKind.SignUp);
        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);
    }

    Dispatch.Dialog.CloseTopDialog();
}

/** The different UI Components supported inside the dialog UI */
export enum DialogKind {
    LocationChooser = 'Location Chooser',
    ContactDetails = 'Contact Details',
    Verification = 'Verification',
    Confirmation = 'Confirmation',
    SimpleErrorMessaging = 'Simple Error Messages',
    RetryErrorMessaging = 'Retry Error Messages',
    DescriptiveErrorMessage = 'Descriptive Error Message',
    /** Simplest form of error messages. It only contains the message and a button to dismiss the message. Smaller than other various dialogs. */
    ErrorMessageWithNoTitle = 'Error Message With No Title',
    CancelBooking = 'Cancel Booking',
    TncConsent = 'Tnc Consent',
    UserPersonalDetails = "Personal Details",
    ContactUs = "Contact Us",
    WhatsNew = "What's New",
    NewFeature = "New Feature",

    // After successfully submitted the enquiry.
    SentEnquiry = "Sent enquiry",
    RemovePaymentCardConfirmation = 'Remove Payment Card Confirmation',
    ShareBooking = "Share Booking",
    SignupPromotionPopup = "Signup Promotion Popup",
    SignUp = "Sign-up Popup",
    SignUpToAddCard = "Sign-up Popup Opened Via Add Card Flow",
    SignupToPayDriverDirect = "Sign-up Popup Opened Via Pay Driver Direct Link",
    LogIn = "Log-in Popup",
    ForgotPassword = "Forgot-Password Popup",
    // This is for logged-in users
    ResetPassword = "Reset-Password Popup",
    // Remove a favourite
    RemoveBookingTemplate = "Remove Booking Template",
    // This dialog is only for dektop version which hosts a google map with taxi
    TaxiTrackingMap = 'Booking tracking for taxi',
    PaymentWallet = 'Payment wallet list',
    AddPaymentCard = 'Add Payment Card',
    MultiTenantSelector = "Multi-tenant login selector",
    PriceGuaranteeFAQ = "PriceGuaranteeFAQ",
    LegalDocumentsConsent = "Consent To Updated Legal Documents",
    AddFavourite = "Add Favourite",

    // To display instructions on how to book for click and collect from a retailor
    ClickAndCollectInfo = "Click And Collect Info",
    IE11DeprecationWarning = "IE 11 Deprecation",

    /** User's address points list. */
    AddressPoints = "Address Points",

    /** Dialog to create an address point */
    CreateAddressPoint = "Create Address Point",

    /** Dialog to edit an address point */
    EditAddressPoint = "Edit Address Point",

    /** Dialog to get confirmation from the user to remove an address point. */
    RemoveAddressPoint = "Remove Address Point",

    /** Confirmation dialog after adding a saved address. */
    CreateAddressConfirmation = "Create Address Confirmation",
    PassengerInfo = "Passenger Info",

    /** Available features list */
    AvailableFeatures = "Available Features",

    /** Dialog to inform the user that the booking cancelled due to payment (preauth) failure. */
    PaymentFailure = "Payment Failure",

    /** Intermediate dialog to select which payment type user wants to add. e.g: Credit card, PayPal etc */
    AddPaymentMethod = "Add Payment Method",

    /** Confirmation dialog to unlink PayPal from a guest user */
    UnlinkPayPal = "Unlink PayPal",

    /** Message to display to the user that there is a technical difficulty and to try again later. This message doesn't include 132227 phone number. Implemented as a temporary solution to reduce the number of calls to contact centre during dispatch system outage. */
    TechnicalDifficulties = "Technical Difficulties",

    /** Provide information about the things the user miss out if they choose to pay the driver directly and give options to either sign up and pay the driver directly or pay online as a guest (with a wallet payment such as Apple Pay or Google Pay) */
    PayDriverDirectInfo = "Pay Driver Direct Info",

    /** Dialog that informs the user to allocate additional seats for check-in luggage when selecting the number of seats in order to avoid capacity issues due to getting a smaller vehicle that can't fit all the luggage. Only applicable to MAXI TAXI. */
    MaxiTaxiSeatGuide = "Maxi Taxi Seat Guide",

    /** Dialog asking the user to call contact center to book a wheelchair accessible taxi on the Christmas day. This is due to the high demand on that day. */
    WatsOnXmasDayMessage = "Wats On Xmas Day Message",
}

export interface WellKnownErrorMessage {
    ProblemText: string;
    SolutionText: string;
}

export interface RetryErrorMessage {
    WellKnownErrorMessage: WellKnownErrorMessage;
    OnClickAction: TryAgainButtonOnClickActionKind;
}

/** All the properties related to detailed error messages. */
export interface DetailedErrorMessage {    

    /** Retry count. To keep track of the number of retries on booking creation failures. */
    RetryCount?: number;

    /** Message title */
    Title?: string;

    /** Image URL as a string */
    ImageUrl?: string;

    /** Error description. Usually displayed as a paragraph below the image. */
    MessageText?: string;

    /** Next dialog to be opened on click of the button */
    DialogToOpen?: DialogKind;

    /** Placeholder of the button */
    ButtonText?: string;

    /** Width of the image. Images are different sizes in different error messages */
    ImageWidth?: string;
}

/** This is used for "Try again" button in some error message dialog
  * Because "Try again" button in different popup might have different actions
  * This type indicate what's the action if "Try again" button on clicked
  */
 export enum TryAgainButtonOnClickActionKind {

    /** This action will refresh tnc page */
    RefreshTncPage = 'RefreshTncPage',

    /** Close a specific, existing dialog */
    Auth0LogIn = 'Auth0LogIn',

    /** This action will refresh privacy page */
     RefreshPrivacyPage = 'RefreshPrivacyPage',

     /** Load all vehicles in the background */
     ReloadVehicles = "Reload Vehicles"
}
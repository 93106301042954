import React from 'react';
import { connect } from 'react-redux';
import './Dialog.scss';
import { DialogKind } from './DialogEntities';
import { ApplicationState } from '../../appState';
import { LocationChooser } from '../../widgets/location/location-chooser/LocationChooser';
import { DialogAction } from './DialogActions';
import RetryErrorMessaging from '../../widgets/general-error-message/RetryErrorMessaging';
import SignupPromotionPopup from '../Authentication/Login/SignupPromotionPopup';
import ResetPassword from '../Authentication/Login/ResetPassword';
import { ResponsiveDialogHost } from './ResponsiveDialogHost';
import { DialogState } from './DialogState';
import TaxiTrackingDialog from '../MyBookings/UI/TaxiTrackingDialog';
import { DialogLoading } from './DialogLoading';
import { DialogConfigLookup } from './DialogConfig';

type PropsFromStore = Omit<DialogState, 'detailedErrorMessage'>;

/** The dispatch function to be injected by react-redux */
interface DispatchProps {
    dispatch: (action: DialogAction) => void;
}

/** Host for all popups (dialogs) in the application. */
class DialogCore extends React.Component<PropsFromStore & DispatchProps> {

    render() {

        // quick exit: none open
        if (!this.props.isAnyOpen) return null;

        const topDialogKind = this.props.topmostDialog!;

        // Wider popup for contact details.
        var dialogClass = "dialog-popup large-popup";

        if (topDialogKind == DialogKind.LocationChooser || topDialogKind === DialogKind.RemoveAddressPoint || topDialogKind == DialogKind.PriceGuaranteeFAQ || topDialogKind === DialogKind.UnlinkPayPal || topDialogKind === DialogKind.TechnicalDifficulties) {
            dialogClass = "dialog-popup small-popup";
        }
        else if (topDialogKind === DialogKind.ErrorMessageWithNoTitle) {
            dialogClass = "dialog-popup small-dialog";
        }
        else if ((topDialogKind == DialogKind.SimpleErrorMessaging) || (topDialogKind == DialogKind.RetryErrorMessaging) || topDialogKind === DialogKind.MaxiTaxiSeatGuide || topDialogKind === DialogKind.WatsOnXmasDayMessage) {
            dialogClass = "dialog-popup simple-error-message-popup";
        }
        else if (topDialogKind == DialogKind.SignUp || topDialogKind === DialogKind.SignUpToAddCard || topDialogKind === DialogKind.SignupToPayDriverDirect) {
            dialogClass = "dialog-popup credential-popup-common signup-popup";
        }
        else if (topDialogKind == DialogKind.LogIn || topDialogKind == DialogKind.TaxiTrackingMap || topDialogKind == DialogKind.ClickAndCollectInfo || topDialogKind == DialogKind.PassengerInfo || topDialogKind === DialogKind.PayDriverDirectInfo) {
            dialogClass = "dialog-popup credential-popup-common login-popup";
        }
        else if (topDialogKind == DialogKind.ForgotPassword) {
            dialogClass = "dialog-popup credential-popup-common forgot-password-popup";
        }
        else if (topDialogKind == DialogKind.ResetPassword) {
            dialogClass = "dialog-popup credential-popup-common resetpassword-popup";
        }
        else if (topDialogKind == DialogKind.MultiTenantSelector) {
            dialogClass = "dialog-popup multi-tenant-selector";
        }
        else if (topDialogKind === DialogKind.CreateAddressConfirmation) {
            dialogClass = "dialog-popup credential-popup-common PriceGuarantee-FAQ-popup";
        }
        else if (topDialogKind === DialogKind.CreateAddressPoint || topDialogKind === DialogKind.EditAddressPoint) {
            dialogClass = "dialog-popup address-points-dialog-panel";
        }

        const config = DialogConfigLookup(topDialogKind);

        let dialogContentStyle = (topDialogKind == DialogKind.SimpleErrorMessaging) || (topDialogKind == DialogKind.RetryErrorMessaging) ? "transparent-background" : "dialog-content";

        if (!config?.Title) {
            dialogContentStyle += " no-title-in-dialog-config";
        }

        return (
            <div className="dialog-feature-root">

                <DialogLoading />

                {/* Dialog panel, anchored to middle of screen, with X button */}
                <div className={dialogClass}>
                    <div className={dialogContentStyle}>
                        {/* One for each type of dialog, but only if open */}
                        {(topDialogKind == DialogKind.LocationChooser) && <LocationChooser />}
                        {(topDialogKind == DialogKind.RetryErrorMessaging) && <RetryErrorMessaging />}
                        {(topDialogKind == DialogKind.SignupPromotionPopup) && <SignupPromotionPopup />}
                        {(topDialogKind == DialogKind.ResetPassword) && <ResetPassword />}
                        {(topDialogKind == DialogKind.TaxiTrackingMap) && <TaxiTrackingDialog />}
                        <ResponsiveDialogHost dialog={topDialogKind} />
                    </div>
                </div>
            </div>
        );
    }

}

/** Connection from store state to local props. */
function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        isAnyOpen: state.dialog.isAnyOpen,
        openDialogs: state.dialog.openDialogs,
        topmostDialog: state.dialog.topmostDialog
    };
}

export const Dialog = connect(mapStateToProps)(DialogCore);